.left-5{
    margin-left: 5px;
}
.bottom{
    background-color: #ffffff;
    padding: 50px 0px;
}
.bottomBox{
    max-width: 980px;
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
}
.copyRight{
    margin-top: 5px;
    color: #b0b0b0;
    font-size: 12px;
}
.linkText{
    cursor: pointer;
    color: #409EFF;
}
.linkText:hover{
    text-decoration: underline;
}
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    .bottom{
        position: relative;
    }
}