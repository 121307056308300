.topBox{
    text-align: left;
    background-color: #FFA000;
}
.navBox{
    padding: 20px;
    margin: 0 auto;
}
.logo{
    height: 6.8vw;
}
.headerTit{
    font-size: 3.6vw;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #ffffff;
}